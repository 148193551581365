import React, { Component } from 'react';

class Ticker3 extends Component {


    render() {

        return (

          <div class="ticker-wrap-3">
            <div class="ticker-wrap">
            <div class="ticker">
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
              <div class="ticker__item">On BSC Chain</div>
            </div>
            </div>
            </div>)
    }
}

export default Ticker3;