import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import mechanism from '../assets/system.png';
import burn from '../assets/fire.png';
import lp from '../assets/exchange.png';
import lock from '../assets/locked.png';
import marketing from '../assets/megaphone.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Benefits extends Component {

    render() {
        return (

            <div class="boxWrap2Story3">
                <div className='blur'>
                    <div class="conT2"><span className='hl2-1'>FROG KEY</span> <span class="hl2">FEATURES</span></div>

                    <div class="aboutDistribution2">

                        <p>
                            <div className='detailsAndCon2'>
                                <img src={mechanism} />

                                <div>
                                    <span className='headerHL'>Reflection Mechanism:</span> 4% of each transaction is redistributed to existing token holders, promoting holding behavior and passive income generation.
                                </div>
                            </div>
                        </p>
                        <p>
                            <div className='detailsAndCon2'>
                                <img src={lp} />

                                <div>
                                    <span className='headerHL'>Automatic Liquidity Pool (LP) Generation:</span> 3% of every transaction is converted into liquidity for exchanges. This strengthens the overall liquidity of the token, reducing price volatility and improving transaction efficiency.
                                </div>

                            </div>
                        </p>

                        <p>
                            <div className='detailsAndCon2'>
                                <img src={burn} />

                                <div>
                                    <span className='headerHL'>Strategic Burn Protocol:</span> 1% of every transaction is permanently removed from circulation, which gradually reduces the total supply, creating a deflationary pressure to enhance value.
                                </div>

                            </div>
                        </p>

                        <p>
                            <div className='detailsAndCon2'>
                                <img src={marketing} />

                                <div>
                                    <span className='headerHL'>Marketing and Development Allocation:</span> 2% of transactions are directed towards a dedicated wallet to fund marketing campaigns and ecosystem development, crucial for the project’s growth.
                                </div>
                            </div>
                        </p>

                        <p>
                            <div className='detailsAndCon2'>
                                <img src={lock} />

                                <div>
                                    <span className='headerHL'>Liquidity Lock:</span> To instill trust in potential investors, liquidity is locked making it inaccessible to developers, thereby securing it against misuse.
                                </div>
                            </div>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Benefits;

