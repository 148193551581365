import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nft from '../assets/frog.jpg';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class About extends Component {

    render() {
        return (

            <div class="boxWrap2Story">

                <div class="about">

                    <img src={nft} />

                    <div class="storyCon">
                        <div class="conT"><span className='hl2-1'>FROG</span><span class="hl2"> INTRODUCTION</span></div>

                        <p>Introducing FROG, a revolutionary token designed to bring innovation and growth to the blockchain ecosystem. Built on robust and secure technology, FROG aims to provide users with a seamless and efficient experience, whether they're engaging in staking, trading, or utilizing decentralized applications. As a versatile and dynamic token, FROG opens up a world of possibilities, offering enhanced liquidity, low transaction fees, and strong community support.</p>
                        <p>FROG is more than just a digital asset; it's a gateway to the future of decentralized finance. With a focus on inclusivity and accessibility, FROG empowers users from all backgrounds to participate in the crypto economy. Its user-friendly interface, coupled with a commitment to security and transparency, ensures that both seasoned traders and newcomers can navigate the complexities of the blockchain with confidence. Join the FROG community today and be part of the next big leap in the digital finance revolution.</p>

                    </div>

                </div>

            </div>
        )
    }
}

export default About;

