import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const buy = () => {
    window.open("https://www.pinksale.finance/");
}

class Tokenomics extends Component {

    render() {
        return (

            <div class="boxWrap2StoryB">
                <div class="conT2"><span className='hl2-1'>FROG TOKENOMICS</span> <br /><span class="hl2">OVERVIEW</span></div>

                <div class="about">
                    <div class="storyCon">
                        <div class="conT"><span className='hl3'>TOKEN</span></div>
                        <div class="tokenomicsCon">Token Name - FROG</div>
                        <div class="tokenomicsCon">Symbol - FROG</div>
                        <div class="tokenomicsCon">Decimal - 18</div>

                        <div class="tokenomicsCon">
                            Contract address - <span class="tokenLink">0x45096743924gvs986428A903A358cd2</span>
                        </div>

                        <div class="tokenomicsCon">Total Supply - 100 Million of FROG</div>

                        <div class="tokenomicsCon">Blockchain - BSC</div>
                    </div>


                    <div class="about3">

                        <div class="storyCon--2">
                            <div class="conT"><span className='hl3'>Tax Breakdown</span></div>

                            <p>BUY/SELL TAX: &nbsp; 2%</p>
                            <p>BURN RATE: &nbsp; 1%</p>
                            <p>TOTAL TAX: &nbsp; 3%</p>                           
                            
                            <button className='btnPresale' onClick={buy}>BUY FROG >></button>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Tokenomics;

